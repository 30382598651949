import "../Styles/HomeWidgets.css";
import { useState } from "react";

export default function Subscribe(props) {
  const [inputActive, setInputActive] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      handleButtonClick(e);
    }
  };

  const handleButtonClick = () => {
    props.onEnter(email);
    setEmail("");
  };

  const version = props.version;
  if (version === 1) {
    return (
      <div className="subscribe-cta-div">
        <div className="subscribe-cta-container">
          <p className="widget-title subscribe">
            Join Thousands of Real Estate Leaders Today.{" "}
          </p>
          <div className={`subscribe-div ${inputActive ? "active" : ""}`}>
            <input
              style={{ width: "95%" }}
              className="subscribe-input"
              placeholder="Enter email address"
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={handleInputKeyUp}
            />
            <button className="subscribe-btn" onClick={handleButtonClick}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    );
  } else if (version === 2) {
    return (
      <div className="subscribe-cta-div">
        <div className="subscribe-cta-container">
          <p className="widget-title" style={{ marginBottom: "1.5rem" }}>
            Experience <span className="brand">The ReaLocal</span> Advantage
          </p>
          <p className="widget-subtitle">
            Join thousands of real estate leaders today.{" "}
          </p>
          <div className={`subscribe-div ${inputActive ? "active" : ""}`}>
            <input
              style={{ width: "95%" }}
              className="subscribe-input"
              placeholder="Enter email address"
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={handleInputKeyUp}
            />
            <button className="subscribe-btn" onClick={handleButtonClick}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    );
  }
}
