import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer(props) {
  return (
    <footer class="footer">
      <div className="footer-container">
        <p>
          © 2024, <i style={{ marginRight: "0.1rem" }}>The ReaLocal</i>. All
          rights reserved.
        </p>
        <div className="more-info"></div>
        <div className="socials">
          <a
            href="https://www.instagram.com/therealocal/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon className="social-icon" />
          </a>
          <a
            href="https://twitter.com/TheReaLocalNews"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon className="social-icon" />
          </a>
          <a
            href="https://www.tiktok.com/@therealocal"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTiktok} className="social-icon" />
          </a>
          <a href="mailto: james@therealocal.com">
            <MailIcon className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
}
