import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import "../Styles/HomeWidgets.css";

export default function Quote({ quote }) {
  return (
    <div className="feature-div">
      <div className="quote-content">
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <FormatQuoteIcon className="feature-icon" />
        </div>
        <div className="feature-div-content">
          <p className="quote">{quote}</p>
        </div>
      </div>
    </div>
  );
}
