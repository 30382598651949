import SoldLogo from "../Media/nav-logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationPage from "./NavigationPage";
export default function NavBar(props) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const handleCloseNavigation = () => {
    setIsActive(false);
  };

  function navigateHome() {
    var route = window.location.href.split("/").pop();

    if (route === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  }
  return (
    <div className="header">
      <div className="header-container">
        <img src={SoldLogo} className="sold-logo" onClick={navigateHome} />
        <MenuIcon className="hamburger" onClick={() => setIsActive(true)} />
      </div>
      {isActive ? (
        <NavigationPage
          isActive={isActive}
          onClose={handleCloseNavigation}
          page={props.page}
        />
      ) : null}
    </div>
  );
}
