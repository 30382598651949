import "../Styles/Home.css";
import NavyLogo from "../Media/navy-logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlankiPhone from "../Media/blankiphone.png";
import PlacesAutocomplete from "react-places-autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import ExampleEmail from "../Media/example-email.png";
import BouncingDotsLoader from "../Components/BouncingDotsLoader";
import SocialProof from "../HomeWidgets.js/SocialProof";
import Subscribe from "../HomeWidgets.js/Subscribe";
import Features from "../HomeWidgets.js/Features";
import { Helmet } from "react-helmet-async";
export default function Home(props) {
  const [inputActive, setInputActive] = useState(false);
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [zipInputActive, setZipInputActive] = useState(false);
  const [flowTracker, setFlowTracker] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleEnter = (value) => {
    if (isEmailValid(value)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setEmail(value);
    }
  };

  function handleExampleClick() {
    //add pdfUrl (should be directed towards amazon s3)
    const pdfUrl = "";
    if (pdfUrl !== "") {
      window.open(pdfUrl, "_blank");
    } else {
      window.open("https://therealocal.com", "_blank");
    }
  }

  const searchOptions = {
    types: ["postal_code"],
    componentRestrictions: { country: "us" },
  };

  function deleteZip(e) {
    const filteredCities = cities.filter((city) => {
      return !city.includes(e.target.id);
    });
    setCities(filteredCities);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(cities, email);
    if (cities.length > 0 && isEmailValid(email)) {
      console.log("");
      const subscriber = {
        email,
        cities,
      };
      setIsLoading(true);
      setTimeout(async () => {
        await axios
          .post("https://therealocal.onrender.com/subscribers", subscriber)
          .then((res) => {
            console.log(res);
            if (res.data.msg !== "FAILED") {
              setIsLoading(false);
              navigate("/subscribed");
            } else {
              //add an alert that an error happened or something
              console.log("clear");
            }
          })
          .catch((err) => console.log(err));
      }, 5000);
    } else {
      alert("Please Complete the Entire Form.");
    }
  };

  function pushCities(e) {
    if (e.target.innerText) {
      const zip = String(e.target.innerText);
      if (!cities.includes(zip)) {
        cities.push(e.target.innerText);
      }
      setCity("");
    }
  }

  const handleInputFocus = () => {
    setInputActive(true);
  };

  const handleZipFocus = () => {
    setZipInputActive(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInputBlur = () => {
    setInputActive(false);
  };

  const handleZipBlur = () => {
    setZipInputActive(false);
  };

  function isEmailValid(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (isEmailValid(email)) {
      setInputActive(false);
      setZipInputActive(true);
      setFlowTracker(flowTracker + 1);
    } else {
      console.log("no");
    }
  };

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter" && isEmailValid(email)) {
      handleButtonClick(e);
    }
  };

  return (
    <div className="page-container">
      <Helmet>
        <title>Welcome to The ReaLocal</title>
        <meta
          name="description"
          content="Stay informed, stay ahead, and stay on top with The ReaLocal — the thriving realtor's ultimate source of hyperlocal market insights."
        />
      </Helmet>
      <NavBar page={"home"} />
      <div className="home-container" style={{ marginBottom: "5rem" }}>
        <div className="logo-container">
          <div className="logo-div">
            <img src={NavyLogo} />
          </div>
        </div>
        <div className="home-content">
          <div className="left-div">
            <div className="CTA-div">
              <p className="CTA-header">
                The Thriving Agent's{" "}
                <span className="highlight">Best Friend. </span>
              </p>
              <div
                style={{
                  height: 10,
                  width: "33%",
                  backgroundColor: "#FF6B6B",
                  marginBottom: 2 + "rem",
                }}
              ></div>
              <p className="CTA-subheader">
                Stay informed, stay ahead, and stay on top with{" "}
                <span className="brand">The ReaLocal</span> — your ultimate
                source of hyperlocal market insights.
              </p>
            </div>

            <div className={`subscribe-div ${inputActive ? "active" : ""}`}>
              <input
                style={{ width: "95%" }}
                className="subscribe-input"
                placeholder="Enter email address"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={email}
                onChange={handleEmailChange}
                onKeyUp={handleInputKeyUp}
              />
            </div>

            <PlacesAutocomplete
              value={city}
              onChange={setCity}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  style={{
                    position: "relative",
                    marginBottom:
                      city.length > 0 || cities.length > 0 ? "15rem" : "0",
                  }}
                  id={`${
                    city.length > 0 || cities.length > 0
                      ? "last"
                      : "last-active"
                  }`}
                  className={`subscribe-div ${zipInputActive ? "active" : ""}`}
                >
                  <input
                    {...getInputProps({
                      placeholder: "Enter zip codes",
                    })}
                    className="subscribe-input"
                    onBlur={handleZipBlur}
                    onFocus={handleZipFocus}
                  />

                  {isLoading ? (
                    <BouncingDotsLoader />
                  ) : (
                    <button className="subscribe-btn" onClick={handleSubmit}>
                      Subscribe
                    </button>
                  )}
                  {city.length > 0 ? (
                    <div className="autocomplete-container">
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-active"
                          : "suggestion";
                        suggestion.description = suggestion.description
                          .split(",", 2)
                          .join(",");
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                            onClick={pushCities}
                          >
                            <AddCircleIcon
                              onClick={() => {
                                const zip = String(suggestion.description);
                                if (!cities.includes(zip)) {
                                  cities.push(suggestion.description);
                                }
                                setCity("");
                              }}
                            />

                            <span className="suggestion">
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    cities.length > 0 && (
                      <div className="items">
                        <div className="items-title">
                          <p>{cities.length === 0 ? "" : "Your Zips: "}</p>
                        </div>
                        <div className="zips">
                          {cities.map((item) => {
                            const zip = item.match(/\d+/g)[0];
                            console.log(zip);
                            return (
                              <div className="zip">
                                <p className="small-p">{zip}</p>
                                <p id={zip} className="sup" onClick={deleteZip}>
                                  X
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            <div className="separate unsubscribe home"></div>
          </div>
          <div className="right-div">
            <div className="example-copy top">
              <p>
                Check Out A{" "}
                <span className="brand click" onClick={handleExampleClick}>
                  Recent Edition
                </span>
                !
              </p>
            </div>
            <div className="iphone-container">
              <img src={BlankiPhone} className="iphone" />
              <img src={ExampleEmail} className="example-email" />
            </div>
            <div className="example-copy bottom">
              <p>
                Check Out A{" "}
                <span className="brand click" onClick={handleExampleClick}>
                  Recent Edition
                </span>
                !
              </p>
            </div>
          </div>
        </div>
      </div>
      <Features />
      <Subscribe version={1} onEnter={handleEnter} />
      <SocialProof version={"quote"} />
      <Subscribe version={2} onEnter={handleEnter} />

      <Footer />
    </div>
  );
}
