import "../Styles/HomeWidgets.css";
import HomeIcon from "@mui/icons-material/Home";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

export default function Features(props) {
  return (
    <div className="features-div">
      <div className="features-container">
        <p className="widget-title" style={{ marginBottom: "1.5rem" }}>
          What's Inside?
        </p>
        <p className="widget-subtitle">
          Each weekly edition of <span className="brand">The ReaLocal</span>{" "}
          contains
        </p>
        <div className="features-content-container">
          <div className="feature-div one">
            <HomeIcon className="feature-icon" />
            <div className="feature-div-content">
              <p className="feature-div-content-title">Housing Trends</p>

              <p className="feature-div-content-content">
                Discover the{" "}
                <span className="feature-highlight">
                  latest nationwide residential property trends
                </span>{" "}
                and eye-opening statistics.
              </p>
              <p className="feature-div-content-content">
                Dive deep with our{" "}
                <span className="feature-highlight">
                  weekly, monthly, and yearly local market data updates
                </span>
                .
              </p>
              <p className="feature-div-content-content">
                Gain <span className="feature-highlight">exclusive access</span>{" "}
                to premium,{" "}
                <span className="feature-highlight">
                  handpicked market reports
                </span>{" "}
                and insightful{" "}
                <span className="feature-highlight">activity summaries</span>.
              </p>
              <p className="feature-div-content-content">
                <span className="feature-highlight">Stay in the know</span> with
                comprehensive coverage of{" "}
                <span className="feature-highlight">
                  all closings, listings, and rentals
                </span>
                .
              </p>
            </div>
          </div>
          <div className="feature-div two">
            <NewspaperIcon className="feature-icon" />
            <div className="feature-div-content">
              <p className="feature-div-content-title">Relevant News</p>
              <p className="feature-div-content-content">
                Dive into the week's most{" "}
                <span className="feature-highlight">
                  relevant nationwide real estate headlines
                </span>
                .
              </p>
              <p className="feature-div-content-content">
                We get it, real estate is all about your local game - you want
                to know what's{" "}
                <span className="feature-highlight">
                  happening in your market
                </span>
                .
              </p>
              <p className="feature-div-content-content">
                That's why we're here, curating the{" "}
                <span className="feature-highlight">
                  most important local news and events
                </span>{" "}
                you need to know to stay ahead.
              </p>
            </div>
          </div>
          <div className="feature-div three">
            <AccessTimeFilledIcon className="feature-icon" />
            <div className="feature-div-content">
              <p className="feature-div-content-title">Bite-Sized Content</p>
              <p className="feature-div-content-content">
                <span className="feature-highlight">
                  What's work without a little pleasure
                </span>
                ?
              </p>
              <p className="feature-div-content-content">
                Explore our carefully curated content and media designed to add
                a dash of excitement and fun to your serious reading – all
                within a{" "}
                <span className="feature-highlight">10-minute read</span>!
              </p>
              <p className="feature-div-content-content">
                Whether it's the latest{" "}
                <span className="feature-highlight">celebrity purchases</span>,
                stunning <span className="feature-highlight">dream homes</span>,
                or captivating{" "}
                <span className="feature-highlight">real estate stories</span>,
                our Dessert Section is where your inner property enthusiast can{" "}
                <span className="feature-highlight">indulge</span>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
