import "../Styles/HomeWidgets.css";
import CB from "../Media/Logos/CB.png";
import Century from "../Media/Logos/century.png";
import EXP from "../Media/Logos/exp.png";
import KW from "../Media/Logos/KW.png";
import REMAX from "../Media/Logos/REMAX.png";
import Sothebys from "../Media/Logos/Sothebys.png";
import Quote from "./Quote";

export default function SocialProof({ version }) {
  if (version === "quote") {
    return (
      <div className="social-proof-div">
        <div className="social-proof-container">
          <p className="widget-title">
            Agents Love <span className="brand">The ReaLocal</span>
          </p>
          <div className="features-content-container">
            <Quote quote="One of the highlights of my Sunday!" />
            <Quote quote="Great newsletter that digs deep into my market." />
            <Quote quote="Packed with relevant, high-quality, and actionable information." />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="social-proof-div">
        <div className="social-proof-container">
          <p className="widget-title">Trusted by Industry Insiders at</p>
          <div className="social-proof-logos">
            <div className="social-proof-logo">
              <img src={CB} />
            </div>
            <div className="social-proof-logo">
              <img src={Century} />
            </div>
            <div className="social-proof-logo">
              <img src={EXP} />
            </div>
          </div>
          <div className="social-proof-logos">
            <div className="social-proof-logo">
              <img src={KW} />
            </div>
            <div className="social-proof-logo">
              <img src={REMAX} />
            </div>
            <div className="social-proof-logo">
              <img src={Sothebys} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
