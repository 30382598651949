import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { Helmet } from "react-helmet-async";
import BouncingDotsLoader from "../Components/BouncingDotsLoader";

export default function Advertise(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [specificGeos, setSpecificGeos] = useState(false);
  const [geos, setGeos] = useState("");
  const [period, setPeriod] = useState("");
  const [referral, setReferral] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [budget, setBudget] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function changed(e) {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "company") {
      setCompany(e.target.value);
    } else if (e.target.name === "title") {
      setTitle(e.target.value);
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
    } else if (e.target.name === "interestGeos") {
      if (e.target.value === "yes") {
        console.log("yes");
        setSpecificGeos(true);
      } else if (e.target.value === "no") {
        setSpecificGeos(false);
      }
    } else if (e.target.name === "geos") {
      setGeos(e.target.value);
    } else if (e.target.name === "period") {
      setPeriod(e.target.value);
    } else if (e.target.name === "budget") {
      setBudget(e.target.value);
    } else if (e.target.name === "referral") {
      setReferral(e.target.value);
    } else if (e.target.name === "addInfo") {
      setAdditionalInfo(e.target.value);
    }
  }

  async function advertise(e) {
    e.preventDefault();
    if (
      name &&
      email &&
      title &&
      company &&
      description &&
      period &&
      budget &&
      referral &&
      additionalInfo &&
      email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      const advertiser = {
        name,
        email,
        company,
        message: "save-ad",
        title,
        description,
        geos,
        period,
        budget,
        referral,
        additionalInfo,
      };
      setLoading(true);

      await axios
        .post("https://therealocal.onrender.com/advertiser", advertiser)
        .then((res) => {
          if (res.data.msg !== "FAILED") {
            setLoading(false);
            navigate("/");
          } else {
            //add an alert that an error happened or something
            console.log("clear");
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please Complete the Entire Form.");
    }
  }
  return (
    <div className="page-container">
      <Helmet>
        <title>Advertise with The ReaLocal</title>
        <meta
          name="description"
          content="Connect with the nation's leading realtors by partnering with The ReaLocal - the undisputed name in hyperlocal real estate news and insights."
        />
      </Helmet>
      <NavBar page={"advertise"} />
      <div
        className="home-container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
          marginBottom: "5rem",
        }}
      >
        <div className="page-title">
          <p>Advertise with Us</p>
        </div>
        <div className="page-content">
          <p className="page-content-text">
            Thank you so much for your interest in{" "}
            <span className="brand" style={{ marginRight: "0.2rem" }}>
              The ReaLocal
            </span>
            ! Every week, our newsletter reaches an engaged, influential, and
            passionate audience of{" "}
            <span className="highlight1">top real estate professionals</span>.
          </p>
          <p className="page-content-text">
            By partnering with us, you'll have the
            <span className="highlight1"> unique opportunity</span> to connect
            with these dedicated and successful individuals, making your brand
            an
            <span className="highlight1"> essential</span> part of their growth
            and success.
          </p>
          <p className="page-content-text">
            Please fill out the below form, and we will get back to you{" "}
            <span className="highlight1">ASAP</span>! We look forward to working
            with you!
          </p>
        </div>
        <hr className="separate advertise" />
        <div className="page-form">
          <form>
            <div className="top-advert">
              <input
                className="input partner"
                id="top-partner-input"
                placeholder="Name"
                name="name"
                value={name}
                onChange={changed}
                required
                style={{ marginTop: 0 }}
              />
              <input
                className="input partner"
                placeholder="Email"
                name="email"
                value={email}
                onChange={changed}
                required
              />
              <input
                className="input partner"
                placeholder="Company"
                name="company"
                value={company}
                onChange={changed}
                required
              />
              <input
                className="input partner"
                placeholder="Job Title"
                name="title"
                value={title}
                onChange={changed}
                required
              />
              <input
                className="input partner"
                placeholder="Company Description"
                name="description"
                value={description}
                onChange={changed}
                required
              />
              <div className="option-div">
                <p>Are you interested in reaching specific geographies?</p>
                <select
                  name="interestGeos"
                  className="select-input"
                  onChange={changed}
                  required
                >
                  <option selected disabled>
                    —
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              {specificGeos ? (
                <div className="option-div optional">
                  <p>Great! Where? </p>
                  <input
                    className="input partner"
                    placeholder="Madison WI, 19108, ..."
                    name="geos"
                    value={geos}
                    onChange={changed}
                    required
                    style={{ marginTop: 0 }}
                  />
                </div>
              ) : null}

              <div className="option-div">
                <p>When would you like to collaborate?</p>
                <select
                  name="period"
                  className="select-input"
                  onChange={changed}
                  required
                >
                  <option selected disabled>
                    —
                  </option>
                  <option value="q2-23">Q3 '23</option>
                  <option value="q3-23">Q4 '23</option>
                  <option value="q4-23">Q1 '24</option>
                  <option value="q1-24">Q2 '24</option>
                </select>
              </div>
              <div className="option-div">
                <p>
                  What budget range are you interested in allocating to this
                  partnership?
                </p>
                <select
                  name="budget"
                  className="select-input"
                  onChange={changed}
                  required
                >
                  <option selected disabled>
                    —
                  </option>

                  <option value="$$$">$$$</option>
                  <option value="$$$$">$$$$</option>
                  <option value="$$$$$">$$$$$</option>
                  <option value="$$$$$$">$$$$$$</option>
                  <option value="TBD">Unsure</option>
                </select>
              </div>
              <div className="option-div">
                <p>How did you hear about us?</p>
                <select
                  name="referral"
                  className="select-input"
                  onChange={changed}
                  required
                >
                  <option selected disabled>
                    —
                  </option>

                  <option value="friends + family">Friends & Family</option>
                  <option value="coworker">Coworker</option>
                  <option value="current sub">Current Subscriber</option>
                  <option value="social media">Social Media</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="option-div">
                <p>Anything else we should know?</p>
                <input
                  className="input partner"
                  placeholder="Marketing Goals, KPIs, etc"
                  name="addInfo"
                  value={additionalInfo}
                  onChange={changed}
                  style={{ marginTop: 0 }}
                />
              </div>
            </div>

            {loading ? (
              <div
                type="submit"
                className="submit partner"
                style={{ position: "relative" }}
                onClick={advertise}
              >
                <div className="text-btn">
                  <p>Submitting</p>
                </div>
                <BouncingDotsLoader color="white" />
              </div>
            ) : (
              <div type="submit" className="submit partner" onClick={advertise}>
                <div className="text-btn">
                  <p>Submit!</p>
                </div>
                <ArrowForwardRoundedIcon
                  style={{ paddingRight: "10px", transform: "scale(1.5)" }}
                />
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
