import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
//Pages
import Home from "./Pages/Home";
import Advertise from "./Pages/Advertise";
import Privacy from "./Pages/Privacy";
import Unsubscribe from "./Pages/Unsubscribe";
import Subscribed from "./Pages/Subscribed";
import NominateROTW from "./Pages/NominateROTW";
export default function App() {
  const helmetContext = {};
  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/subscribed" element={<Subscribed />} />
            <Route path="/agent-of-the-week" element={<NominateROTW />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}
