import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import BouncingDotsLoader from "../Components/BouncingDotsLoader";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

export default function Unsubscribe(props) {
  const [pressed, setPressed] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const [email, setEmail] = useState("");

  const [unsubscribeVisible, setUnsubscribeVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  async function unsubscribe() {
    if (email) {
      setIsLoading(true);
      await axios
        .post("https://therealocal.onrender.com/unsubscribe", { email: email })
        .then((res) => {
          if (res.data.success) {
            setIsLoading(false);
            navigate("/");
          } else {
            alert("Error: Try again.");
          }
        });
    } else {
    }
  }

  return (
    <div className="page-container">
      <Helmet>
        <title>Unsubscribe from The ReaLocal</title>
        <meta name="description" content="Unsubscribe from The ReaLocal." />
      </Helmet>
      <NavBar page={"unsubscribe"} />
      <div
        className="home-container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
        }}
      >
        <div className="page-title">
          <p>Unsubscribe</p>
        </div>
        <div className="page-content">
          <p
            className={
              unsubscribeVisible
                ? "page-content-title inactive"
                : "page-content-title"
            }
          >
            Are you sure you want to unsubscribe from{" "}
            <span className="brand" style={{ marginRight: "0.2rem" }}>
              The ReaLocal
            </span>
            ?
          </p>
          <div className="unsubscribe-div">
            <div
              className={
                unsubscribeVisible
                  ? "subscribed remain inactive"
                  : "subscribed remain"
              }
              onClick={() => {
                navigate("/");
              }}
            >
              <p style={{ pointerEvents: "none" }}>
                No, I want to remain subscribed.
              </p>
              <ArrowForwardRoundedIcon className="unsub-arrow" />
            </div>
            <div
              className={
                unsubscribeVisible
                  ? "subscribed cancel inactive"
                  : "subscribed cancel"
              }
              onClick={() => {
                setUnsubscribeVisible(!unsubscribeVisible);
              }}
            >
              <p style={{ pointerEvents: "none" }}>
                Yes, I want to unsubscribe.{" "}
              </p>
              <ArrowForwardRoundedIcon className="unsub-arrow" />
            </div>
          </div>
          {unsubscribeVisible ? (
            <>
              <div className="separate unsubscribe"></div>
              <div
                className={`subscribe-div unsubscribe ${
                  inputActive ? "active" : ""
                }`}
                style={{ position: "relative" }}
              >
                <input
                  className="subscribe-input unsubscribe"
                  placeholder="Enter email address"
                  value={email}
                  onFocus={() => setInputActive(true)}
                  onBlur={() => setInputActive(false)}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {isLoading ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "0rem",
                      right: "1rem",
                    }}
                  >
                    <BouncingDotsLoader />
                  </div>
                ) : (
                  <button className="subscribe-btn unsub" onClick={unsubscribe}>
                    Unsubscribe
                  </button>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
}
