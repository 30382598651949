import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

export default function Subscribed(props) {
  return (
    <div className="page-container">
      <NavBar page={"subscribed"} />
      <div
        className="home-container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
        }}
      >
        <div className="page-title">
          <p>Thanks for Signing Up!</p>
        </div>
        <div className="page-content">
          <p className="page-content-text" style={{ fontSize: "2rem" }}>
            Welcome to <i style={{ color: "#ff6b6b" }}>The ReaLocal</i> — we're
            beyond thrilled to have you on board!
          </p>
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "1rem" }}
          >
            To ensure that you receive all of your newsletters, please keep an
            eye out for a <span className="highlight1">confirmation email</span>{" "}
            from us.
          </p>
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "1rem" }}
          >
            This email contains important information about your subscription,
            and it may end up in your
            <span className="highlight1"> spam folder</span>, so please check
            there if you don't see it in your inbox.
          </p>
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "1rem" }}
          >
            Once you
            <span className="highlight1"> confirm your subscription</span>,
            you'll be all set to receive our newsletter straight to your inbox!
            😊
          </p>
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "1rem" }}
          >
            To hear more from us, check us out on{" "}
            <a
              href="https://www.instagram.com/therealocal/"
              target="_blank"
              rel="noreferrer"
              className="highlight-link"
            >
              Instagram
            </a>
            ,{" "}
            <a
              href="https://twitter.com/TheReaLocalNews"
              target="_blank"
              rel="noreferrer"
              className="highlight-link"
            >
              Twitter
            </a>
            , or{" "}
            <a
              href="https://www.tiktok.com/@therealocal"
              target="_blank"
              rel="noreferrer"
              className="highlight-link"
            >
              TikTok
            </a>
            . Or just shoot us an{" "}
            <a href="mailto: james@therealocal.com" className="highlight-link">
              email
            </a>{" "}
            and say hi!
          </p>
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "1rem" }}
          >
            See you soon! 😉
          </p>{" "}
          <p
            className="page-content-text"
            style={{ fontSize: "2rem", paddingTop: "3rem" }}
          >
            <p className="brand-sig">―The ReaLocal</p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
