const BouncingDotsLoader = (props) => {
  const color = props.color;
  return (
    <div className="bouncing-loader">
      <div style={{ backgroundColor: color }}></div>
      <div style={{ backgroundColor: color }}></div>
      <div style={{ backgroundColor: color }}></div>
    </div>
  );
};

export default BouncingDotsLoader;
