import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { Helmet } from "react-helmet-async";
import BouncingDotsLoader from "../Components/BouncingDotsLoader";

export default function NominateROTW(props) {
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [yourLocation, setYourLocation] = useState("");
  const [yourFirm, setYourFirm] = useState("");
  const [yourNominee, setYourNominee] = useState("");
  const [theirEmail, setTheirEmail] = useState("");
  const [theirLocation, setTheirLocation] = useState("");
  const [theirFirm, setTheirFirm] = useState("");
  const [yourReasoning, setYourReasoning] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function advertise(e) {
    e.preventDefault();
    if (
      yourName &&
      yourEmail &&
      yourLocation &&
      yourFirm &&
      yourNominee &&
      theirEmail &&
      theirLocation &&
      theirFirm &&
      yourReasoning &&
      yourEmail.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ) &&
      theirEmail.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      const nomination = {
        yourName,
        yourEmail,
        yourLocation,
        message: "save-rotw",
        yourFirm,
        yourNominee,
        theirEmail,
        theirLocation,
        theirFirm,
        yourReasoning,
      };
      setLoading(true);

      await axios
        .post("https://therealocal.onrender.com/rotw-nomination", nomination)
        .then((res) => {
          if (res.data.msg !== "FAILED") {
            setLoading(false);
            navigate("/");
          } else {
            //add an alert that an error happened or something
            console.log("clear");
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please Complete the Entire Form.");
    }
  }
  return (
    <div className="page-container">
      <Helmet>
        <title>Nominate a Star Agent</title>
        <meta
          name="description"
          content="Show your appreciation and nominate a star realtor for The ReaLocal's presigious Realtor of the Week award!"
        />
      </Helmet>
      <NavBar page={"nominate"} />
      <div
        className="home-container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
          marginBottom: "5rem",
        }}
      >
        <div className="page-title">
          <p>Nominate a Star Agent!</p>
        </div>
        <div className="page-content">
          <p className="page-content-text">
            Know a standout broker who deserves the{" "}
            <span className="rotw-highlight">spotlight</span>? Nominate them for{" "}
            <span className="brand">The ReaLocal's</span>{" "}
            <i className="The ReaLocal's">Agent of the Week</i> feature!
          </p>

          <p className="page-content-text">
            Celebrate their <span className="rotw-highlight">achievements</span>{" "}
            by completing the form below. Your{" "}
            <span className="rotw-highlight">nomination</span> could earn them a
            well-deserved <span className="rotw-highlight">spot</span> in our
            weekly <span className="rotw-highlight">spotlight</span>!
          </p>
        </div>
        <hr className="separate" />
        <div className="page-form">
          <form>
            <div className="top-advert">
              <input
                className="input partner"
                id="top-partner-input"
                placeholder="Your Name"
                name="name"
                value={yourName}
                onChange={(e) => setYourName(e.target.value)}
                required
                style={{ marginTop: 0 }}
              />
              <input
                className="input partner"
                placeholder="Your Email"
                name="email"
                value={yourEmail}
                onChange={(e) => setYourEmail(e.target.value)}
                required
              />
              <input
                className="input partner"
                placeholder="Your Location"
                name="company"
                value={yourLocation}
                onChange={(e) => setYourLocation(e.target.value)}
                required
              />
              <input
                className="input partner"
                placeholder="Your Firm"
                name="title"
                value={yourFirm}
                onChange={(e) => setYourFirm(e.target.value)}
                required
              />
              <div className="option-div">
                <p style={{ marginBottom: "1rem" }}>
                  Who is your nominee for Realtor of the Week?
                </p>
                <input
                  className="input partner"
                  placeholder="Their Name"
                  name="title"
                  style={{ marginTop: "0rem" }}
                  value={yourNominee}
                  onChange={(e) => setYourNominee(e.target.value)}
                  required
                />
              </div>
              <input
                className="input partner"
                placeholder="Their Email"
                name="title"
                value={theirEmail}
                onChange={(e) => setTheirEmail(e.target.value)}
                required
              />
              <input
                className="input partner"
                placeholder="Their Location"
                name="title"
                value={theirLocation}
                onChange={(e) => setTheirLocation(e.target.value)}
                required
              />
              <input
                className="input partner"
                placeholder="Their Firm"
                name="title"
                value={theirFirm}
                onChange={(e) => setTheirFirm(e.target.value)}
                required
              />
              <div className="option-div">
                <p style={{ marginBottom: "1rem" }}>
                  Why should {yourNominee || "they"} be nominated for ROTW?{" "}
                </p>
                <input
                  className="input partner"
                  placeholder="Your Reasoning"
                  name="title"
                  style={{ marginTop: "0rem" }}
                  value={yourReasoning}
                  onChange={(e) => setYourReasoning(e.target.value)}
                  required
                />
              </div>
            </div>

            {loading ? (
              <div
                type="submit"
                className="submit partner"
                style={{ position: "relative" }}
                onClick={advertise}
              >
                <div className="text-btn">
                  <p>Submitting</p>
                </div>
                <BouncingDotsLoader color="white" />
              </div>
            ) : (
              <div type="submit" className="submit partner" onClick={advertise}>
                <div className="text-btn">
                  <p>Nominate!</p>
                </div>
                <ArrowForwardRoundedIcon
                  style={{ paddingRight: "10px", transform: "scale(2)" }}
                />
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
