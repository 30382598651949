import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { Helmet } from "react-helmet-async";

export default function Privacy(props) {
  return (
    <div className="page-container">
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy policy for The ReaLocal" />
      </Helmet>
      <NavBar page={"privacy"} />
      <div
        className="home-container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
          marginBottom: "5rem",
        }}
      >
        <div className="page-title">
          <p>Privacy Policy</p>
        </div>
        <div className="page-content">
          <p className="page-content-text">
            This Privacy Policy outlines how{" "}
            <span className="brand" style={{ marginRight: "0.15rem" }}>
              The ReaLocal
            </span>{" "}
            ("we," "our," or "us") collects, uses, discloses, and protects the
            personal information provided by subscribers ("you") of{" "}
            <span className="brand" style={{ marginRight: "0.15rem" }}>
              The ReaLocal
            </span>{" "}
            newsletter ("Newsletter"). We are committed to safeguarding your
            privacy and ensuring the security of your personal information. By
            subscribing to and using our Newsletter, you agree to the terms
            outlined in this Privacy Policy.
          </p>
          <p className="page-content-title">1. Information We Collect</p>
          <p className="page-content-text">
            We collect the following types of personal information from our
            subscribers:
          </p>
          <p className="page-content-text">
            Email Addresses: We collect your email address when you subscribe to{" "}
            <span className="brand" style={{ marginRight: "0.15rem" }}>
              The ReaLocal
            </span>{" "}
            newsletter.
          </p>
          <p className="page-content-text">
            Geographical Information: We may collect the zip codes you provide
            to us, which represent your desired local market areas.
          </p>
          <p className="page-content-title">2. How We Use Your Information</p>
          <p className="page-content-text">
            We use the collected information for the following purposes:
          </p>
          <p className="page-content-text">
            Personalization: We use your geographical information to tailor the
            content of the Newsletter to your desired local markets.
          </p>
          <p className="page-content-text">
            Newsletter Delivery: We use your email address to send you our
            weekly newsletter, containing information relevant to your local
            real estate market.
          </p>
          <p className="page-content-title">3. Data Sharing and Disclosure</p>
          <p className="page-content-text">
            We do not sell, trade, or rent your personal information to third
            parties. We may, however, share your information with trusted
            service providers who assist us in operating our Newsletter, subject
            to confidentiality agreements. We may also share your information if
            required by law or to protect our rights and interests.
          </p>
          <p className="page-content-title">4. Security</p>
          <p className="page-content-text">
            We take the security of your personal information seriously. We
            implement appropriate technical, physical, and administrative
            measures to protect your information from unauthorized access,
            disclosure, alteration, or destruction.
          </p>
          <p className="page-content-title">
            5. Disclaimer and Liability Notice
          </p>
          <p className="page-content-text">
            Please note that our Newsletter is intended for informational
            purposes only and does not provide legal, financial, or investment
            advice. The views expressed are those of the author and not
            necessarily endorsed by any other entity. We make no representations
            or warranties about the accuracy, completeness, or reliability of
            the information provided. Your reliance on the content is at your
            own risk, and we shall not be liable for any loss or damage arising
            from its use.
          </p>
          <p className="page-content-title">
            6. Third-Party Links and Endorsements
          </p>
          <p className="page-content-text">
            Our Newsletter may contain links to third-party websites or mention
            third-party products/services. We do not endorse these
            products/services, and your interactions with third-party websites
            are subject to their respective privacy policies and terms of use.
          </p>
          <p className="page-content-title">7. Intellectual Property</p>
          <p className="page-content-text">
            The content of the Newsletter, including text, images, and any other
            intellectual property, is the exclusive property of{" "}
            <span className="brand" style={{ marginRight: "0.15rem" }}>
              The ReaLocal
            </span>{" "}
            and may not be reproduced or distributed without our prior written
            permission.
          </p>
          <p className="page-content-title">
            8. Changes to this Privacy Policy
          </p>
          <p className="page-content-text">
            We reserve the right to update or modify this Privacy Policy at any
            time without prior notice. Any changes will be effective upon
            posting on our website.
          </p>

          <p className="page-content-title">9. Contact Us</p>
          <p className="page-content-text">
            If you have any questions, concerns, or requests related to your
            personal information or this Privacy Policy, please contact us at
            [james@therealocal.com].
          </p>
          <p className="page-content-text">
            By subscribing to{" "}
            <span className="brand" style={{ marginRight: "0.15rem" }}>
              The ReaLocal
            </span>{" "}
            newsletter, you acknowledge that you have read and understood this
            Privacy Policy and agree to its terms.
          </p>
          <p
            className="page-content-text"
            style={{ fontStyle: "italic", fontSize: 12 }}
          >
            Last Updated: 08/28/2023
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
