import CloseIcon from "@mui/icons-material/Close";

export default function NavigationPage(props) {
  const closeNavigation = () => {
    props.onClose();
  };
  const page = props.page;

  return (
    <div className={`navigation-page ${props.isActive ? "active" : ""}`}>
      <div className="navigation-page-container">
        <div className="header" style={{ border: "none" }}>
          <div
            className="header-container nav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon className="close-icon" onClick={closeNavigation} />
          </div>
        </div>
        <div className="navigation-items">
          <a
            href="/"
            target="_blank"
            className={page === "home" ? "active" : ""}
          >
            Home
          </a>
          <a
            href="/agent-of-the-week"
            target="_blank"
            className={page === "nominate" ? "active" : ""}
          >
            Agent Of The Week
          </a>
          <a
            href="/privacy"
            target="_blank"
            className={page === "privacy" ? "active" : ""}
          >
            Privacy Policy
          </a>

          <a
            href="/unsubscribe"
            target="_blank"
            className={page === "unsubscribe" ? "active" : ""}
          >
            Unsubscribe
          </a>
        </div>
      </div>
    </div>
  );
}
